import { IconButton, MenuItem, TextField, ThemeProvider, createTheme } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import {object, string} from 'yup';
import FacebookLogo from '../../../../public/images/facebook-logo-white.svg';
// import { countrySelectOptions } from '../../../../utils/cabinClasses';
import { httpPost } from '../../../../utils/httpClient';
import styles from '../../Authentication.module.scss';
// import { AuthInput } from '../AuthInput/AuthInput';
import GoogleAuth from '../../../utils/GoogleAuth';
import { FlightSearchAutocomplete } from '../../../FlightSearch/FlightSearchAutocomplete/FlightSearchAutocomplete';
import { WORLD_COUNTRIES } from '../../../../config/worldCountries';

const theme = createTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#D4A976',
    },
  },
  components: {
    // Name of the component
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: '#ffffff',
          borderRadius: '10px',
        },

        input: {
          padding: '13.5px 14px' 
        }
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          borderRadius: '10px',
        },
      },
    },
  },
});

const sortedCountries = WORLD_COUNTRIES.slice().sort((a, b) => {
  const nameA = a.name.common.toLowerCase();
  const nameB = b.name.common.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
});

const signUpSchema = object().shape({
  firstName: string().nullable().required('Please enter your first name'),
  lastName: string().nullable().required('Please enter your last name'),
  email: string().nullable().required('Please enter your email'),
  country: string().nullable().required('Please enter your country'),
});

export const SignUp = ({
  changeState,
  authCompleted,
  customTopContent,
  customBottomContent,
  singleStepSignUp,
  // placeholdersOnly = false,
  // signUpText = 'Continue',
  darkMode = false,
  sourcePage,
  // isSignupTextShown = true
}) => {
  const [loading, setLoading] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState(null);
  // const [toggleSignUp, setToggleSignUp] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [userExist, setUserExist] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const userStore = useSelector((state) => state.user?.value);
  const router = useRouter();

  useEffect(() => {
    const country = sortedCountries.find(
      (country) => country?.code === userStore?.country
    );
    setDefaultCountry(country);
  }, [userStore]);
  const login = (values, provider) => {
    setLoading(true);
    httpPost(`/api/login/${provider}`, values)
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          response.json().then((res) => {
            if (res.isNewUser) {
              const tagManagerArgs = {
                dataLayer: {
                  event: 'accountCreated',
                  userCountry: userStore?.country.toUpperCase(),
                  pageType: router?.pathname,
                  source: provider,
                  membershipId: userStore?.membershipNumber,
                  signupSource: sourcePage
                },
              };
              TagManager.dataLayer(tagManagerArgs);
              changeState('setAirport');
            } else {
              authCompleted();
            }
          });
          return;
        }
      })
      .catch(async (error) => {
        setErrorMessage((await error).msg || (await error).reason?.[0].msg);
        console.log((await error).msg || (await error).reason?.[0].msg);
      });
  };

  const signUp = (value) => {
    setLoading(true);
    setUserExist(null);
    const registrationValue = {...value, 
      userAirport: {
        code: value.userAirport?.Iata,
        name: value.userAirport?.ShortName,
        isCity: !value.userAirport?.ParentAirport
      }
    };

    Promise.all([
      httpPost('/api/user/registration', registrationValue),
      httpPost(`/api/email/exists`, { email: value.email }),
    ])
      .then((resolved) => {
        setLoading(false);
        if (resolved[1].ok) {
          resolved[1].json().then((res) => {
            if (res.exists) {
              setUserExist(value.email);
            }
          });
        }
        if (resolved[0].ok) {
          if (!singleStepSignUp) {
            const tagManagerArgs = {
              dataLayer: {
                event: 'accountCreated',
                userCountry: userStore?.country.toUpperCase(),
                pageType: router?.pathname,
                membershipId: userStore?.membershipNumber,
                signupSource: sourcePage
              },
            };
            TagManager.dataLayer(tagManagerArgs);
            changeState('setPassword');
          } else {
            authCompleted();
            if (!router.pathname.includes('flightsearch')) {
              window.location.href = '/profile?accountCreated=true';
            } else {
              window.open('/profile?accountCreated=true', '_blank');
            }
          }
        }
        if (!resolved[0].ok) {
          setLoading(false);
          throw resolved[0].json();
        }
      })
      .catch(async (error) => {
        setErrorMessage((await error).msg || (await error).reason?.[0].msg);
        console.log((await error).msg || (await error).reason?.[0].msg);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={cn(styles.authContainer ,darkMode ? styles.authDarkTheme : '')}
      onClick={() => {
        setCollapsed(false);
      }}>
        {defaultCountry && (
          // <Formik
          //   initialValues={{
          //     firstName: '',
          //     lastName: '',
          //     email: '',
          //     country: defaultCountry.value.toUpperCase(),
          //     language: 'en',
          //     password: '',
          //     username: '',
          //     userid: null,
          //   }}
          //   validationSchema={signUpSchema}
          //   onSubmit={(values) => {
          //     signUp(values);
          //   }}
          //   className="flex flex-col items-center"
          // >
          //   {({ errors, touched }) => (
          //     <Form>
          //       <div className="flex flex-col items-center">
          //       {!customBottomContent && (
          //         <div className='sm:w-full w-3/4'>
          //           <span className={cn(styles.haveAccount)}>
          //             Already a member? {' '}
          //             <span onClick={() => changeState('login')}>Log in</span>
          //           </span>

          //           <hr className='my-3' />
          //         </div>
    
          //         )}
          //       <span className={cn(styles.subHeader, 'text-center font-libre')}>Claim your free membership in <b>The Club</b></span>
          //         {!customTopContent && (
          //           <h2
          //             className={cn(
          //               styles.header,
          //               'text-center font-fragment my-4'
          //             )}
          //           >
          //             Fly Business Class for Less!
          //           </h2>
          //         )}
          //         {customTopContent ? customTopContent : <></>}
          //         <div
          //           className={cn(
          //             styles.snLogin,
          //             'flex justify-around items-center'
          //           )}
          //         >
          //           <FacebookLogin
          //             appId={process.env.facebookAppId}
          //             reauthenticate={false}
          //             render={(renderProps) => {
          //               return (
          //                 <button
          //                   type="button"
          //                   className={styles.facebookLogin}
          //                   onClick={renderProps.onClick}
          //                 >
          //                   <FacebookLogo />
          //                   <span className={'ml-6'}>Continue with Facebook</span>
          //                 </button>
          //               );
          //             }}
          //             onSuccess={(response) => {
          //               console.log('Login Success!', response);
          //             }}
          //             onProfileSuccess={(response) => {
          //               console.log('Get Profile Success!', response);
          //             }}
          //             callback={(response) => {
          //               login(
          //                 { provider: 'facebook', token: response.accessToken },
          //                 true
          //               );
          //             }}
          //           />
          //         </div>
          //         <div
          //           className={cn(
          //             styles.snLogin,
          //             'flex justify-around items-center'
          //           )}
          //         >
          //           <GoogleAuth
          //             // callback on response
          //             config={{
          //               theme: 'outline',
          //               size: 'large',
          //               text: 'continue_with',
          //             }}
          //             handleCredentialResponse={(res) =>
          //               login({ provider: 'google', token: res.credential }, true)
          //             }
          //           />
          //         </div>

          //         <div className={'font-libre mt-4 mb-0'}>or</div>
          //         <div className="max-w-sm flex flex-col items-center w-1/2 sm:w-3/4">
          //           <AuthInput
          //             name="email"
          //             type="text"
          //             label={!placeholdersOnly && 'Email'}
          //             placeholder={
          //               placeholdersOnly ? placeholdersOnly : 'Enter your email'
          //             }
          //             onClick={() => {
          //               setToggleSignUp(true);
          //             }}
          //             errorMessage={
          //               (userExist && (
          //                 <>
          //                   {userExist} already exists!
          //                   {!singleStepSignUp && (
          //                     <span className={cn(styles.haveAccountInError)}>
          //                       Already signed up? {' '}
          //                       <span onClick={() => changeState('login')}>
          //                         Log in
          //                       </span>
          //                     </span>
          //                   )}
          //                 </>
          //               )) ||
          //               (touched.email && errors.email)
          //             }
          //           />
          //           {toggleSignUp && (
          //             <>
          //               <AuthInput
          //                 name="firstName"
          //                 type="text"
          //                 label={!placeholdersOnly && 'First Name'}
          //                 placeholder={
          //                   placeholdersOnly ? placeholdersOnly : 'First Name'
          //                 }
          //                 errorMessage={touched.firstName && errors.firstName}
          //               />
          //               <AuthInput
          //                 name="lastName"
          //                 type="text"
          //                 label={!placeholdersOnly && 'Last Name'}
          //                 placeholder={
          //                   placeholdersOnly ? placeholdersOnly : 'Last Name'
          //                 }
          //                 errorMessage={touched.lastName && errors.lastName}
          //               />
          //               <AuthInput
          //                 name="country"
          //                 type="select"
          //                 label={!placeholdersOnly && 'Country of residence'}
          //                 placeholder={
          //                   placeholdersOnly
          //                     ? placeholdersOnly
          //                     : 'Country of residence'
          //                 }
          //                 errorMessage={touched.country && errors.country}
          //                 options={countrySelectOptions}
          //               />
          //             </>
          //           )}
          //         </div>
          //         {errorMessage && (
          //           <div className={cn(styles.error, 'mt-4')}>
          //             <IconButton
          //               color="inherit"
          //               onClick={() => setErrorMessage(null)}
          //               aria-label="close"
          //               className={styles.closeDialog}
          //             >
          //               <CloseIcon />
          //             </IconButton>
          //             <span>{errorMessage}</span>
          //           </div>
          //         )}
          //         <div
          //           className={cn(
          //             'flex flex-col text-center justify-center w-full mb-6 lg:mb-6'
          //           )}
          //         >
          //           {loading && <div className={styles.loader} />}
          //           <button
          //             className={cn(
          //               styles.loginButton,
          //               'text-white font-serif mx-auto'
          //             )}
          //           >
          //             {signUpText}
          //           </button>
          //         </div>

          //         {/* {!customBottomContent && (
          //           <span className={cn(styles.haveAccount)}>
          //             Already signed up? {' '}
          //             <span onClick={() => changeState('login')}>Log in</span>
          //           </span>
          //         )} */}
          //       </div>
          //     </Form>
          //   )}
          // </Formik>

          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              country: defaultCountry.code,
              language: 'en',
              password: '',
              username: '',
              userid: null,
              userAirport: null,
            }}
            onSubmit={(values) => {
              signUp(values);
            }}
            validationSchema={signUpSchema}
            className="flex flex-col items-end"
          >
          {({ setFieldValue, errors, values, touched }) => (
            <Form className="sm:flex sm:flex-col w-full lg:flex-row">
                {!customBottomContent && (
                  <div className='sm:w-full w-3/4 m-auto'>
                    <span className={cn(styles.haveAccount)}>
                      Already a member? {' '}
                      <span onClick={() => changeState('login')}>Log in</span>
                    </span>

                  <hr className='my-3' />
                </div>
      
                )}
                <span className={cn(styles.subHeader, 'text-center font-libre')}>Claim FREE membership of <b>The Club</b></span>
                {!customTopContent && (
                  <h2
                    className={cn(
                      styles.header,
                      'text-center font-fragment my-4'
                    )}
                  >
                    Find the cheapest fares and unbeatable deals. <br />
                    Free to join, sign up & start saving instantly!
                  </h2>
                )}
                {customTopContent ? customTopContent : <></>}

              {/* {isSignupTextShown && ( 
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                  {checkUserCountry(userStore?.country) && (
                    <>
                      <ul className='lg:text-lg list-disc ml-4'>
                        <li>Membership is free</li>
                        <li>Save with amazing <b>FlightDeals</b></li>
                        <li>Access fabulous member-only fares</li>
                        
                      </ul>

                      <ul className='lg:text-lg list-disc ml-4'>
                        <li >Add your own destinations in MapMyTravel</li>
                        <li >Weekly newsletters and deal alerts</li>
                        <li >Enjoy reading exclusive content</li>
                      </ul>
                    </>
                  )}

                  {!checkUserCountry(userStore?.country) && (
                    <>
                    <ul className='lg:text-lg list-disc ml-4'>
                      <li>Membership is free</li>
                      <li>Access fabulous member-only fares</li>
                      <li>Save with great flight offers</li>
                    </ul>
                    <ul className='lg:text-lg list-disc ml-4'>
                      <li >Add your own destinations in MapMyTravel</li>
                      <li >Weekly newsletters</li>
                      <li >Enjoy reading exclusive content</li>
                    </ul>
                    </>
                  )}
                </div>
               )} */}

              <p className='text-center font-libre'>Sign up or <span className="cursor-pointer underline" onClick={() => changeState('login')}>login</span> to unlock member-only fares & private deals.  <a href="/membership-club">See full membership benefits.</a></p>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 my-4'>
                <div className="flex flex-col items-end font-libre">
                  <div
                    className="flex w-full flex-col items-end"
                  >
                    <TextField
                      name="email"
                      type="email"
                      error={!!userExist || !!errors.email}
                      fullWidth
                      placeholder="Enter your email"
                      onChange={(event) => {
                        setFieldValue('email', event.target.value);
                      }}
                      className="mb-5"
                    />
                    {errors.email && (
                      <div className={styles.inputErrorMessage}>{errors.email}</div>
                    )}
                    {userExist && (
                      <div
                        className={styles.inputErrorMessage}
                      >{`Еmail ${userExist} already exists!`}</div>
                    )}
      
                    {!collapsed && (
                      <>
                        <TextField
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          error={!!errors.firstName}
                          className="mb-5"
                          fullWidth
                          onChange={(event) => {
                            setFieldValue('firstName', event.target.value);
                          }}
                        />
                        {errors.firstName && (
                          <div className={styles.inputErrorMessage}>
                            {errors.firstName}
                          </div>
                        )}
                        <TextField
                          className="mb-5"
                          name="lastName"
                          error={!!errors.lastName}
                          type="text"
                          fullWidth
                          placeholder="Last Name"
                          onChange={(event) => {
                            setFieldValue('lastName', event.target.value);
                          }}
                        />
                        {errors.lastName && (
                          <div className={styles.inputErrorMessage}>
                            {errors.lastName}
                          </div>
                        )}
                        <TextField
                          select
                          name="country"
                          className="mb-5"
                          error={!!errors.country}
                          fullWidth
                          defaultValue={
                            sortedCountries.find(
                              (opt) => opt.code === userStore?.country.toUpperCase()
                            )?.code || null
                          }
                          onChange={(event) => {
                            setFieldValue('country', event.target.value);
                          }}
                        >
                          {sortedCountries.map((option, index) => (
                            <MenuItem
                              key={option.code + index}
                              value={option.code}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                className="mr-2 inline-block"
                                src={option.flags?.svg}
                                alt={option.flags?.alt}
                              />
                              {option.name.common}
                            </MenuItem>
                          ))}
                        </TextField>
                        {errors.country && (
                          <div className={styles.inputErrorMessage}>
                            {errors.country}
                          </div>
                        )}

                        <FlightSearchAutocomplete
                          setFieldValue={setFieldValue}
                          name="userAirport"
                          label="Preferred departure Airport"
                          showLabel={false}
                          // icon={<FlightTakeoffIcon />}
                          error={!!(touched.userAirport && errors.userAirport)}
                          patch={{ value: values.userAirport }}
                        />

                         {errorMessage && (
                          <div className={cn(styles.error, 'mt-4')}>
                            <IconButton
                              color="inherit"
                              onClick={() => setErrorMessage(null)}
                              aria-label="close"
                              className={styles.closeDialog}
                            >
                              <CloseIcon />
                            </IconButton>
                            <span>{errorMessage}</span>
                          </div>
                        )}

                        {loading && (
                          <div
                            className={cn(
                              'flex flex-col text-center justify-center w-full'
                            )}
                          >
                            <div className={styles.loader} />
                          </div>
                        )}
                        {/* <div
                          className={cn(
                            styles.snLogin,
                            'flex justify-center items-center sm:mx-auto font-libre my-4'
                          )}
                        >
                          <span className="mr-5">or signup with</span>
                          <FacebookLogin
                            appId={process.env.facebookAppId}
                            reauthenticate={false}
                            render={(renderProps) => {
                              return (
                                <button onClick={renderProps.onClick}>
                                  <FacebookLogo className={styles.snLogo} />
                                </button>
                              );
                            }}
                            onSuccess={(response) => {
                              console.log('Login Success!', response);
                            }}
                            onProfileSuccess={(response) => {
                              console.log('Get Profile Success!', response);
                            }}
                            callback={(res) =>
                              login({ token: res.accessToken }, 'facebook')
                            }
                          />
                          <GoogleAuth
                            styles={styles.snGoogleButton}
                            // callback on response
                            handleCredentialResponse={(res) =>
                              login({ token: res.credential }, 'google')
                            }
                          />
                        </div> */}
                      </>
                    )}
      
                  </div>
                </div>
                <div className="flex flex-col items-start sm:mt-4">
                  <button
                    type="submit"
                    className={cn(
                      styles.loginButton,
                      'w-full sm:mx-auto'
                    )}
                  >
                    Sign up for free
                  </button>
                  <div
                    className={cn(
                      styles.snLogin,
                      'flex-col w-full font-libre my-4'
                    )}
                  >
                    <div className="mr-5 mb-3 w-full text-center">or with</div>
                    <div className='flex justify-center items-center'>
                      <FacebookLogin
                        appId={process.env.facebookAppId}
                        reauthenticate={false}
                        render={(renderProps) => {
                          return (
                            <button onClick={renderProps.onClick}>
                              <FacebookLogo className={styles.snLogo} />
                            </button>
                          );
                        }}
                        onSuccess={(response) => {
                          console.log('Login Success!', response);
                        }}
                        onProfileSuccess={(response) => {
                          console.log('Get Profile Success!', response);
                        }}
                        callback={(res) =>
                          login({ token: res.accessToken }, 'facebook')
                        }
                      />
                      <GoogleAuth
                        styles={styles.snGoogleButton}
                        // callback on response
                        handleCredentialResponse={(res) =>
                          login({ token: res.credential }, 'google')
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={cn(
                      styles.caption,
                      'text-white font-libre my-4 text-sm'
                    )}
                  >
                    By signing up to The Club you accept our <a href="/view/privacy_policy">Privacy Policy</a>, <a href="/view/terms_conditions">Terms
                    & Conditions</a> and <a href="/view/cookies_policy">Cookies Policy</a>.
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        )}
        {customBottomContent ? customBottomContent : <></>}
      </div>
    </ThemeProvider>
  );
};
