import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { httpPost } from '../../../../utils/httpClient';
// import { AuthCheckbox } from '../AuthCheckbox/AuthCheckbox';
import { AuthInput } from '../AuthInput/AuthInput';
import styles from '../../Authentication.module.scss';
import { Form, Formik } from 'formik';
import FacebookLogo from '../../../../public/fb.svg';
import GoogleAuth from '../../../utils/GoogleAuth';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

export const Login = ({ changeState, authCompleted, darkMode = false, sourcePage }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const userStore = useSelector((state) => state.user?.value);
  const router = useRouter();
  const login = (values, external = false) => {
    setLoading(true);
    setErrorMessage(null);
    httpPost(external ? `/api/login/${external}` : '/api/login', values).then(
      (response) => {
        setLoading(false);
        if (response.ok) {
          const tagManagerArgs = {
            dataLayer: {
              event: 'login',
              userCountry: userStore?.country.toUpperCase(),
              pageType: router?.pathname,
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          // authCompleted();
          if (external) {
            response.json().then((res) => {
              if (res.isNewUser) {
                const tagManagerArgs = {
                  dataLayer: {
                    event: 'accountCreated',
                    userCountry: userStore?.country.toUpperCase(),
                    pageType: router?.pathname,
                    source: external,
                    membershipId: userStore?.membershipNumber,
                    signupSource: sourcePage
                  },
                };
                TagManager.dataLayer(tagManagerArgs);
                changeState('setAirport');
              } else {
                authCompleted();
              }
            });
          } else {
            authCompleted();
          }

          return;
        } else {
          if (!external) {
            setErrorMessage('Wrong email or password \n');
          }
        }
      }
    );
  };

  return (
    <div className={darkMode ? styles.authDarkTheme : ''}>
      <Formik
        initialValues={{
          username: '',
          password: '',
          remember_me: true,
        }}
        onSubmit={(values) => {
          login({ ...values, remember_me: Number(values['remember_me']) });
        }}
        className="flex flex-col items-center"
      >
        {() => (
          <Form>
            <span className={cn(styles.subHeader, 'text-center font-libre')}>The Club</span>
            <span className={cn(styles.header, 'text-center my-4 font-fragment')}>
              Login
            </span>
            <div className={cn(styles.fieldInfo)}>
              {/* <p>
                Login for your personalized search results, the best travel
                offers&#8230;
              </p> */}
            </div>
            <div className="max-w-sm flex flex-col items-center mx-auto">
              <AuthInput name="username" type="text" label="Email" />
              <AuthInput name="password" type="password" label="Password" />
              {/* <AuthCheckbox
                id="auth-checkbox"
                name="remember_me"
                label="Remember me"
              /> */}
            </div>
            <div
              className={cn(
                'flex flex-col text-center justify-center w-full mt-4 mb-8'
              )}
            >
              <span
                onClick={() => changeState('passwordReset')}
                className={styles.forgotPassword}
              >
                Forgot password?
              </span>
              {errorMessage && (
                <div className={styles.error}>
                  <IconButton
                    color="inherit"
                    onClick={() => setErrorMessage(null)}
                    aria-label="close"
                    className={styles.closeDialog}
                  >
                    <CloseIcon />
                  </IconButton>
                  <span>
                    {errorMessage}
                    <br /> Try again or create a new password &nbsp;
                    <span
                      className={styles.errorLink}
                      onClick={() => changeState('passwordReset')}
                    >
                      here.
                    </span>
                  </span>
                </div>
              )}
              {loading && <div className={styles.loader} />}

              <button
                type="submit"
                className={cn(
                  styles.loginButton,
                  'text-white font-serif mx-auto'
                )}
              >
                Login
              </button>
            </div>
            <div
              className={cn(styles.snLogin, 'flex justify-center items-center')}
            >
              <span className="mr-5">or login with</span>
              <FacebookLogin
                appId={process.env.facebookAppId}
                autoLoad={false}
                render={(renderProps) => {
                  return (
                    <button type="button" onClick={renderProps.onClick}>
                      <FacebookLogo className={styles.snLogo} />
                    </button>
                  );
                }}
                onSuccess={(response) => {
                  console.log('Login Success!', response);
                }}
                onProfileSuccess={(response) => {
                  console.log('Get Profile Success!', response);
                }}
                callback={(res) =>
                  login({ token: res.accessToken }, 'facebook')
                }
              />

              <GoogleAuth
                styles={styles.snGoogleButton}
                // callback on response
                handleCredentialResponse={(res) =>
                  login({ token: res.credential }, 'google')
                }
              />
            </div>
            <span className={cn(styles.haveAccount)}>
              New to BusinessClass.com? {' '}
              <span onClick={() => changeState('signUp')}>
                Join The Club for free
              </span>
            </span>
          </Form>
        )}
      </Formik>
    </div>
  );
};
